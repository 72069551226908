import { Shipment } from '@laco/backend-interfaces';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../Components/Loading';
import { ShipmentSelector } from '../Components/ShipmentSelector';

export const GssShipment = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = React.useState<Shipment.ShipmentHeader | undefined>();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    axios
      .get(`/shipment/${id}`, {
        baseURL: 'https://kiosk.lacoinc.com',
      })
      .then((res) => res.data)
      .then((data: Shipment.ShipmentHeader) => setData(data))
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  }, []);

  if (error)
    return (
      <>
        <h1 color='red'>Unable to load data</h1>
        You may close the window
      </>
    );
  if (data === undefined) return <Loading />;

  return <ShipmentSelector data={data} />;
};
