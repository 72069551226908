import { Shipment } from '@laco/backend-interfaces';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { PackingList } from '../Documents/PackingList';
import { ShippingLabel } from '../Documents/ShippingLabel';
import { SiemensPackingList } from '../Documents/SiemensPackingList';
import { samplePackingListHeader } from '../ExampleData/PackingList';
import '../Styles/app.css';

const pageOverflowPackingList: Shipment.ShipmentHeader = {
  ...samplePackingListHeader,
  lines: Array(20).fill(samplePackingListHeader.lines).flat(),
};

export const Home = () => {
  const documents = [
    'Packing List',
    'Packing List Overflow',
    'Shipping Label',
    'Siemens Packing List',
    'Siemens Packing List Overflow',
  ];
  const [currentDoc, setCurrentDoc] = React.useState(documents[2]);

  const getPDF = () => {
    switch (currentDoc) {
      case 'Packing List':
        return <PackingList packingList={samplePackingListHeader} />;
      case 'Packing List Overflow':
        return <PackingList packingList={pageOverflowPackingList} />;
      case 'Shipping Label':
        return (
          <ShippingLabel
            shipmentLines={samplePackingListHeader.lines as Shipment.ShipmentLine[]}
            customerName={samplePackingListHeader.customer?.displayName}
            customerPO={samplePackingListHeader.customerPO}
            packingList={samplePackingListHeader.shipmentNumber}
          />
        );
      case 'Siemens Packing List':
        return <SiemensPackingList packingList={samplePackingListHeader} />;
      case 'Siemens Packing List Overflow':
        return <SiemensPackingList packingList={pageOverflowPackingList} />;
      default:
        return <PackingList packingList={samplePackingListHeader} />;
    }
  };

  return (
    <div>
      <select value={currentDoc} style={{ width: 300 }} onChange={(event) => setCurrentDoc(event.target.value)}>
        {documents.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
      <PDFViewer key={currentDoc} className='viewer'>
        {getPDF()}
      </PDFViewer>
      {/* <ShippingLabel /> */}
    </div>
  );
};
