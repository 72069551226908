import { Shipment } from '@laco/backend-interfaces';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import Logo from '../Assets/laco-logo.png';
import { barcodeImage } from '../Styles/barcode-image';
import { generalPageStyles, rowPadding, tableBorderSize, tableVerticalPadding } from '../Styles/reactPDF';

const styles = StyleSheet.create({
  packingListNumb: {
    justifyContent: 'center',
    textAlign: 'center',
    flex: 1,
    fontSize: 30,
  },
  shipToBillTo: {
    flex: 3,
    paddingHorizontal: 10,
    paddingBottom: 10,
    textAlign: 'justify',
  },
});

interface PackingListProps {
  packingList: Shipment.ShipmentHeader;
}

export const SiemensPackingList = (props: PackingListProps) => {
  const { packingList } = props;

  return (
    <Document
      title={`Packing List - ${packingList.shipmentNumber}`}
      subject={`Packing List - ${packingList.shipmentNumber}`}
      author='LACO INC'
      creator='Traverse'
    >
      <Page size='LETTER' style={generalPageStyles.page}>
        <View
          style={[
            generalPageStyles.row,
            { paddingBottom: rowPadding, justifyContent: 'space-around', alignItems: 'center' },
          ]}
          fixed
        >
          <Image style={generalPageStyles.logo} src={Logo} />
          <Text style={styles.packingListNumb}>Packing List</Text>
          <Image
            style={[generalPageStyles.bigBarcode, { maxWidth: 140, marginRight: 40, marginTop: 10 }]}
            src={barcodeImage(packingList.shipmentNumber)}
          />
        </View>
        <View style={generalPageStyles.row}>
          <View style={styles.shipToBillTo}>
            <Text style={generalPageStyles.textHeader}>Bill To:</Text>
            <Text>{packingList.customer?.name}</Text>
            <Text>{packingList.customer?.address?.display}</Text>
          </View>
          <View style={styles.shipToBillTo}>
            <Text style={generalPageStyles.textHeader}>Ship To:</Text>
            {/* //TODO: Need shipTo name  */}
            <Text>{packingList.shipTo}</Text>
            {packingList.attention ? <Text>{`Attn: ${packingList.attention}`}</Text> : null}
          </View>
        </View>
        <View style={generalPageStyles.table}>
          <View style={generalPageStyles.rowWithBorder}>
            <Text style={generalPageStyles.tableCellStart}>SO</Text>
            {/* <Text style={generalPageStyles.tableCell}>Ship Num</Text> */}
            <Text style={generalPageStyles.tableCell}>Cust No</Text>
            <Text style={generalPageStyles.tableCell}>Order Date</Text>
            <Text style={generalPageStyles.tableCell}>Tax</Text>
            <Text style={generalPageStyles.tableCell}>Sales Rep</Text>
            <Text style={[generalPageStyles.tableCellEnd, { flex: 2 }]}>Mark Shipment</Text>
          </View>
          <View style={[generalPageStyles.rowWithBorder]}>
            <Text style={generalPageStyles.tableCellStart}>{packingList.salesOrderNumber}</Text>
            {/* <Text style={generalPageStyles.tableCellStart}>{packingList.shipmentNumber}</Text> */}
            <Text style={generalPageStyles.tableCellStart}>{packingList.customer?.accountNumber}</Text>
            {/* //TODO replace with data - order date */}
            <Text style={generalPageStyles.tableCellStart}>12/3 /2020</Text>
            {/* //TODO replace with data - taxable */}
            <Text style={generalPageStyles.tableCellStart}>T</Text>
            {/* //TODO replace with data - sales rep */}
            <Text style={generalPageStyles.tableCellStart}>No Salesman</Text>
            {/* // TODO replace with data - mark shipment */}
            <Text style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}></Text>
          </View>
          <View style={generalPageStyles.rowWithBorder2}>
            <Text style={generalPageStyles.tableCellStart}>Ship Date</Text>
            <Text style={[generalPageStyles.tableCell, { flex: 2 }]}>Vendor Number</Text>
            <Text style={[generalPageStyles.tableCell, { flex: 2 }]}>F.O.B. Point</Text>
            <Text style={[generalPageStyles.tableCellEnd, { flex: 2 }]}>Shipped Via</Text>
          </View>
          <View style={generalPageStyles.rowWithBorder}>
            <Text style={generalPageStyles.tableCellStart}>
              {packingList.shippedAt ? new Date(packingList.shippedAt).toLocaleDateString() : null}
            </Text>
            <Text style={[generalPageStyles.tableCellStart, { flex: 2 }]}>30136815</Text>
            {/* //TODO replace with packingList.fob */}
            <Text style={[generalPageStyles.tableCellStart, { flex: 2 }]}>Origin</Text>
            <Text style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}>
              {packingList.shippingMethod}
            </Text>
          </View>
          <View style={generalPageStyles.rowWithBorder2}></View>
          <View style={[generalPageStyles.rowWithPadding, { textAlign: 'justify', paddingBottom: rowPadding }]}>
            <Text style={{ marginRight: 10, fontSize: 12 }}>Customer PO:</Text>
            <Image
              style={[generalPageStyles.mediumBarcode, { maxWidth: 120 }]}
              src={barcodeImage(packingList.customerPO || '')}
            />
          </View>
          {/* bottom table */}
          {/* <View style={generalPageStyles.rowWithBorder} fixed>
            <View style={generalPageStyles.tableCellStart}></View>
            <Text style={[generalPageStyles.tableCellStart, { flex: 3 }]}>Quantity</Text>
            <View style={generalPageStyles.tableCellStart}></View>
            <View style={[generalPageStyles.tableCellStart, { flex: 2 }]}></View>
            <View style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}></View>
          </View>
          <View style={generalPageStyles.row} fixed>
            <Text style={generalPageStyles.tableCellStart}>Item</Text>
            <View style={[generalPageStyles.tableCellStart, generalPageStyles.rowWithBorder, { flex: 3 }]}>
              <Text style={styles.quantityCell}>Order</Text>
              <Text style={styles.quantityCell}>B/O</Text>
              <Text style={styles.quantityCell}>Ship</Text>
            </View>
            <Text style={generalPageStyles.tableCellStart}>Unit</Text>
            <Text style={[generalPageStyles.tableCellStart, { flex: 2 }]}>Product</Text>
            <Text style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}>
              Description
            </Text>
          </View> */}
          {/* {packingList.lines.map((line, index) =>
            typeof line !== 'string' ? (
              <View key={index} style={index === 0 ? generalPageStyles.row : generalPageStyles.rowWithBorder} wrap={false}>
                <Text style={generalPageStyles.tableCellStart}>{`${line.orderLineNumber}${
                  line.orderReleaseNumber > 1 ? ` / ${line.orderReleaseNumber}` : ''
                }`}</Text>
                <Text style={generalPageStyles.tableCellStart}>
                  {typeof line.salesOrderLine !== 'string' ? line.salesOrderLine.quantity || '' : ''}
                </Text>
                <Text style={styles.quantityCell}>
                  {typeof line.salesOrderLine !== 'string' ? line.salesOrderLine.qtyOpen || '' : ''}
                </Text>
                <Text style={styles.quantityCell}>{line.packagedQuantity}</Text>
                <Text style={generalPageStyles.tableCellStart}>{line.productUnitOfMeasure}</Text>
                <Text style={[generalPageStyles.tableCellStart, { flex: 2 }]}>
                  {`${line.customerProductCode} Rev. ${line.productRevision}`}
                </Text>
                <Text style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}>
                  {line.productDescription}
                </Text>
              </View>
            ) : null,
          )}
          <View style={generalPageStyles.rowWithBorder2}></View> */}
        </View>
        {packingList.lines.map((line, index) =>
          typeof line !== 'string' ? (
            <View
              key={index}
              style={{ flexDirection: 'column', borderTop: tableBorderSize, paddingBottom: tableVerticalPadding }}
              wrap={false}
            >
              <View style={[generalPageStyles.rowWithPadding, { alignItems: 'center' }]}>
                <Text style={{ marginRight: 10, width: 40 }}>Line:</Text>
                <Image
                  style={[generalPageStyles.mediumBarcode, { maxWidth: 80, marginRight: 10 }]}
                  src={barcodeImage(line.orderLineNumber)}
                />
                <Text>{`Release: ${line.orderReleaseNumber}`}</Text>
              </View>
              <View
                style={[generalPageStyles.rowWithPadding, { alignItems: 'center', justifyContent: 'space-between' }]}
              >
                <View style={[generalPageStyles.row, { flex: 12, alignItems: 'center' }]}>
                  <Text style={{ marginRight: 10, width: 40 }}>Item:</Text>
                  <Image
                    style={[generalPageStyles.mediumBarcode, { maxWidth: 150, marginRight: 10 }]}
                    src={barcodeImage(line.customerProductCode)}
                  />
                  <Text>{`Revision: ${line.productRevision}`}</Text>
                </View>
                <Text style={{ flex: 11, textAlign: 'right' }}>{line.productDescription}</Text>
              </View>
              <View style={[generalPageStyles.rowWithPadding, { alignItems: 'center' }]}>
                <Text style={{ marginRight: 10, width: 40 }}>Shipped:</Text>
                <Image
                  style={[generalPageStyles.mediumBarcode, { maxWidth: 80, marginRight: 10 }]}
                  src={barcodeImage(line.packagedQuantity)}
                />
                <Text style={{ marginRight: 10 }}>{`B/O: ${line.requiredQuantity - line.packagedQuantity}`}</Text>
                <Text style={{ marginRight: 10 }}>{`Ordered: ${line.requiredQuantity}`}</Text>
                <Text>{`Unit: ${line.productUnitOfMeasure}`}</Text>
              </View>
              {line['serials'] && line['serials'].length > 0 ? (
                <View style={[generalPageStyles.rowWithPadding, { alignItems: 'center' }]}>
                  <Text style={{ marginRight: 10, width: 40 }}>Serial(s):</Text>
                  {(line['serials'] as string[]).map((s) => (
                    <Image
                      key={s}
                      style={[generalPageStyles.mediumBarcode, { maxWidth: 80, marginRight: 10 }]}
                      src={barcodeImage(s)}
                    />
                  ))}
                </View>
              ) : undefined}
              {/* <Text style={generalPageStyles.tableCellStart}>{`${line.orderLineNumber}${
                line.orderReleaseNumber > 1 ? ` / ${line.orderReleaseNumber}` : ''
              }`}</Text>
              <Text style={generalPageStyles.tableCellStart}>
                {typeof line.salesOrderLine !== 'string' ? line.salesOrderLine.quantity || '' : ''}
              </Text>
              <Text style={styles.quantityCell}>
                {typeof line.salesOrderLine !== 'string' ? line.salesOrderLine.qtyOpen || '' : ''}
              </Text>
              <Text style={styles.quantityCell}>{line.packagedQuantity}</Text>
              <Text style={generalPageStyles.tableCellStart}>{line.productUnitOfMeasure}</Text>
              <Text style={[generalPageStyles.tableCellStart, { flex: 2 }]}>
                {`${line.customerProductCode} Rev. ${line.productRevision}`}
              </Text>
              <Text style={[generalPageStyles.tableCellStart, generalPageStyles.tableCellEnd, { flex: 2 }]}>
                {line.productDescription}
              </Text> */}
            </View>
          ) : null,
        )}
        <Text
          style={generalPageStyles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
