import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GssShipment } from './Pages/GssShipment';
import { Home } from './Pages/Home';
import { LocalStoreShipment } from './Pages/LocalStoreShipment';
import { PackingList } from './Pages/PackingList';

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/pl/:id' component={PackingList} />
        <Route path='/gss/:id' component={GssShipment} />
        <Route path='/local' component={LocalStoreShipment} />
        <Route path='/' component={Home} />
      </Switch>
    </Router>
  );
};
