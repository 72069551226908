import { StyleSheet } from '@react-pdf/renderer';

export const tableBorderSize = 1;
export const tableVerticalPadding = 4;
export const rowPadding = 10;

export const generalPageStyles = StyleSheet.create({
  page: {
    fontFamily: 'Times-Roman',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    marginTop: 10,
  },
  labelPage: {
    fontFamily: 'Times-Roman',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  pageNumber: {
    position: 'absolute',
    top: 5,
    right: 20,
  },
  logo: {
    maxWidth: 148.66,
    maxHeight: 48.66,
    flexGrow: 0,
  },
  table: { textAlign: 'center', fontSize: 10 },
  tableCell: {
    paddingVertical: tableVerticalPadding,
    flex: 1,
  },
  tableCellStart: {
    borderLeft: tableBorderSize,
    flex: 1,
    paddingVertical: tableVerticalPadding,
  },
  tableCellEnd: {
    borderRight: tableBorderSize,
    flex: 1,
    paddingVertical: tableVerticalPadding,
  },
  rowWithBorder: {
    flexDirection: 'row',
    borderTop: tableBorderSize,
  },
  rowWithBorder2: {
    flexDirection: 'row',
    borderTop: tableBorderSize * 2,
  },
  row: {
    flexDirection: 'row',
  },
  rowWithPadding: {
    flexDirection: 'row',
    paddingTop: rowPadding,
  },
  bigBarcode: {
    height: 40,
  },
  mediumBarcode: {
    height: 30,
  },
  barcode: {
    height: 20,
  },
  textHeader: {
    marginBottom: 5,
  },
});
