import { Shipment } from '@laco/backend-interfaces';
import { AppBar, Box, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { PackingList as DefaultPackingList } from '../Documents/PackingList';
import { ShippingLabel } from '../Documents/ShippingLabel';
import { SiemensPackingList } from '../Documents/SiemensPackingList';

const useStyles = makeStyles((theme: Theme) => ({
  root: { flexGrow: 1, backgroundColor: theme.palette.background.paper },
}));

const a11yProps = (index: number) => ({ id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` });

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

interface ShipmentSelectorProps {
  data: Shipment.ShipmentHeader;
}
export const ShipmentSelector = (props: ShipmentSelectorProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { data } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const lines: Shipment.ShipmentLine[] = [];
  data.lines.forEach((l) => {
    if (typeof l !== 'string') lines.push(l);
  });
  return (
    <div className={classes.root}>
      <AppBar position='sticky' color='default'>
        <Tabs value={value} onChange={handleChange} aria-label='simple tabs example' variant='fullWidth'>
          <Tab label={`Packing List (${data.shipmentNumber})`} {...a11yProps(0)} />
          <Tab label={`Shipping Label (${data.shipmentNumber})`} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PDFViewer className='viewer'>
          {data.customer?.accountNumber === 'SIE001' ? (
            <SiemensPackingList packingList={data} />
          ) : (
            <DefaultPackingList packingList={data} />
          )}
        </PDFViewer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PDFViewer className='viewer'>
          <ShippingLabel
            shipmentLines={lines}
            customerPO={data.customerPO}
            customerName={data.customer?.name}
            packingList={data.shipmentNumber}
          />
        </PDFViewer>
      </TabPanel>
    </div>
  );
};
