import { Shipment } from '@laco/backend-interfaces';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import Logo from '../Assets/laco-logo.png';
import { barcodeImage } from '../Styles/barcode-image';
import { generalPageStyles } from '../Styles/reactPDF';

const styles = StyleSheet.create({
  flexStartRow: {
    ...generalPageStyles.row,
    alignItems: 'flex-start',
    fontSize: 10,
  },
  alignmentAdjustment: {
    marginTop: -2,
  },
});

interface ShippingLabelProps {
  shipmentLines: Shipment.ShipmentLine[];
  customerPO?: string;
  customerName?: string;
  packingList: string;
}

export const ShippingLabel = (props: ShippingLabelProps) => {
  const { shipmentLines, customerName, customerPO, packingList } = props;
  return (
    <Document
      title={`Shipping Label - ${packingList}`}
      subject={`Packing Label - ${packingList}`}
      author='LACO INC'
      creator='Traverse'
    >
      {shipmentLines.map((line, index) => (
        <Page
          key={index}
          orientation='landscape'
          size={{ width: 216, height: 288 }}
          style={generalPageStyles.labelPage}
        >
          <View>
            <View style={[generalPageStyles.row, { justifyContent: 'space-around', alignItems: 'flex-start' }]}>
              <Image style={{ flex: 2 }} src={Logo} />
              <Text style={{ flex: 2, textAlign: 'center', fontSize: 10 }}>{`Packaged On\n${
                line.packagedAt ? new Date(line.packagedAt).toLocaleDateString() : ''
              }`}</Text>
              <Image style={[generalPageStyles.mediumBarcode, { flex: 3 }]} src={barcodeImage(line.shipmentNumber)} />
            </View>
            <Text style={{ paddingTop: 1, fontSize: 12 }}>{customerName}</Text>
            <View style={[generalPageStyles.row, { paddingTop: 5, alignItems: 'flex-start' }]}>
              <Text style={[styles.alignmentAdjustment, { width: 32, fontSize: 10 }]}>PO:</Text>
              <Image
                style={[generalPageStyles.mediumBarcode, { maxWidth: 180 }]}
                src={barcodeImage(customerPO || '')}
              />
              {/* <Text style={{ flex: 2, textAlign: 'right' }}>{`Release: ${line.orderReleaseNumber}`}</Text> */}
            </View>
            <View
              style={[generalPageStyles.row, { alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }]}
            >
              <View style={styles.flexStartRow}>
                <Text
                  style={[styles.alignmentAdjustment, { width: 32 }]}
                >{`Line: Rel: ${line.orderReleaseNumber}`}</Text>
                <Image
                  style={[generalPageStyles.mediumBarcode, { maxWidth: 80 }]}
                  src={barcodeImage(line.orderLineNumber)}
                />
              </View>
              <Text style={{ width: 146, textAlign: 'right' }}>Box 1 of 1</Text>
            </View>
            <View
              style={[generalPageStyles.row, { alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }]}
            >
              <View style={styles.flexStartRow}>
                <Text style={[styles.alignmentAdjustment, { width: 32 }]}>{`Qty: ${line.productUnitOfMeasure}`}</Text>
                <Image
                  style={[generalPageStyles.mediumBarcode, { maxWidth: 80 }]}
                  src={barcodeImage(line.packagedQuantity)}
                />
              </View>
              <Text style={{ width: 140, textAlign: 'right', fontSize: 8 }}>{line.productDescription}</Text>
            </View>
            <View style={[styles.flexStartRow, { paddingTop: 5 }]}>
              <Text style={[styles.alignmentAdjustment, { width: 32 }]}>{`Item: Rev ${line.productRevision}`}</Text>
              <Image
                style={[generalPageStyles.mediumBarcode, { maxWidth: 180 }]}
                src={barcodeImage(line.customerProductCode)}
              />
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
