import { Shipment } from '@laco/backend-interfaces';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

export const PackingList = () => {
  const [ready, setReady] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  // TODO: how to get token ?
  const token = '';

  React.useEffect(() => {
    axios
      .get(`/shipment/header/${id}/packing-list`, {
        baseURL: 'https://traverse-api.dev.lacoinc.com',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data: Shipment.ShipmentHeader) => {
        console.log(data);
        setReady(true);
      });
  }, []);

  if (!ready) return null;

  return <div>PL here {id}</div>;
};
