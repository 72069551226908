import { Shipment } from '@laco/backend-interfaces';
import React from 'react';
import { Loading } from '../Components/Loading';
import { ShipmentSelector } from '../Components/ShipmentSelector';

export const LocalStoreShipment = () => {
  const [data, setData] = React.useState<Shipment.ShipmentHeader | undefined>();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    try {
      const now = new Date();
      const json = localStorage.getItem('pageData');
      if (typeof json !== 'string') throw new Error('missing data');
      const parsed = JSON.parse(json);
      if (typeof parsed.exp !== 'number' || parsed.exp < now.getTime()) throw new Error('expired');
      setData(parsed.data);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  }, []);

  if (error)
    return (
      <>
        <h1 color='red'>Unable to load data</h1>
        You may close the window
      </>
    );
  if (data === undefined) return <Loading />;

  return <ShipmentSelector data={data} />;
};
