import { SalesOrder, Shipment } from '@laco/backend-interfaces';
import { PartnerAddressType } from '@laco/backend-interfaces/dist/partners';
export const samplePackingListHeader: Shipment.ShipmentHeader = {
  _id: 'shipmentHeader_id',
  shipFromLocation: 'Reno',
  isKit: false,
  shipmentNumber: 'PL121481-0001',
  salesOrderHeaderId: '',
  salesOrderNumber: 'SO121481',
  customer: {
    _id: 'customer_id',
    accountNumber: 'SIE001',
    name: 'SIEMENS INDUSTRY, INC',
    displayName: 'SIEMENS INDUSTRY, INC',
    address: {
      active: true,
      approved: true,
      address: 'SIEMENS INDUSTRY, INC\nPO BOX 4356 MC-IMA030\nPORTLAND OR 97208',
      type: [PartnerAddressType.BILL_TO],
      display: 'SIEMENS INDUSTRY, INC\nPO BOX 4356 MC-IMA030\nPORTLAND OR 97208',
      _id: '',
    },
  },
  customerPO: '4508889051',
  // TODO shippingMethod: 'SIEMENS TRAFFIC',
  shipTo: '7318 FRENCH ROAD\nSACRAMENTO CA 95828',
  attention: '',
  status: Shipment.ShipmentHeaderStatus.PACKAGED,
  packagedAt: new Date(),
  shippedAt: new Date(),
  lines: [
    {
      _id: 'shipmentLine_id-1',
      shipmentHeader: '',
      shipmentNumber: 'PL121481-0001',
      salesOrderLine: {
        _id: '',
        header: '',
        lineNumber: 10,
        product: '',
        price: 0,
        releases: [],
        needReview: false,
        status: SalesOrder.SalesOrderLineStatus.CLOSED,
        total: 0,
        quantity: 5,
        qtyOpen: 3,
        isKit: false,
      },
      salesOrderRelease: '',
      orderLineNumber: 10,
      orderReleaseNumber: 2,
      productRef: '',
      shipFromLocationRef: '',
      shipFromLocationName: 'Reno, NV',
      customerProductCode: 'RS:A834770134534434',
      productDescription: 'HARNESS,INTERIOR,LEFT,A&B-CAR,',
      productRevision: 'B',
      productUnitOfMeasure: 'EA',
      productManufacturerName: '',
      productUnitPrice: 0,
      requiredQuantity: 5,
      packagedQuantity: 9999999999999,
      dueDate: new Date().toDateString(),
      shipDate: new Date().toDateString(),
      packagedAt: new Date(),
      shippedAt: new Date(),
    },
  ],
  trackingNumber: 'Tracking',
};
